import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
// import Zoom from "react-medium-image-zoom";

//Components
import Layout from '../components/layout';
import Title from '../components/title';
import SEO from '../components/seo';
import Map from '../components/map';
import Breadcrumbs from '../components/breadcrumb';

//Icons
import { BsClockHistory } from 'react-icons/bs';
import { GrMoney } from 'react-icons/gr';

//styles
import { Container, Row, Col } from 'react-bootstrap';
import * as styles from '../styles/templates/experience.module.scss';

export const query = graphql`
  query ($slug: String!) {
    contentfulExperiences(slug: { eq: $slug }) {
      slug
      title
      price
      duration
      images {
        gatsbyImageData(quality: 100, width: 300, layout: FIXED)
      }
      location {
        lat
        lon
      }
      description {
        raw
      }
      createdAt(formatString: "ddd Mo MMM YYYY")
    }
  }
`;

const Experience = ({ data }) => {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  );
  const Text = ({ children }) => <p className={styles.text}>{children}</p>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;

        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </div>
        );
      },
    },
  };

  const experience = data.contentfulExperiences;

  return (
    <>
      <Layout>
        {/* Add SEO Here */}
        <SEO title='Experiences' />
        <Title picture title={experience.title} />
        <Container>
          <Breadcrumbs
            links={[
              {
                text: 'Experiences',
                link: '/experiences',
              },
              {
                text: experience.title,
                link: `/experiences/${experience.slug}`,
              },
            ]}
          />
          <Col lg={12} className='my-3'>
            {/* Photo Gallery */}
            {experience.images && (
              <Container>
                {experience.images.map((image) => {
                  return (
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      className='mx-auto'
                      alt={image.title}
                    />
                  );
                })}
              </Container>
            )}
          </Col>

          <Col lg={8} className='mx-auto text-start '>
            <Row className='justify-content-center text-center'>
              <Col className='d-flex justify-content-center'>
                {experience.duration && (
                  <p className='mx-2'>
                    <BsClockHistory className='me-1' />
                    {experience.duration}
                  </p>
                )}
                {experience.price && (
                  <p className='mx-2'>
                    <GrMoney className='me-1' />
                    {experience.price}
                  </p>
                )}
              </Col>
            </Row>
            <div className={`${styles.content} mt-3`}>
              {renderRichText(experience.description, options)}
            </div>
          </Col>
        </Container>

        <Container>
          <Map
            center={{
              lat: experience.location.lat,
              lng: experience.location.lon,
            }}
          />
        </Container>
      </Layout>
    </>
  );
};

export default Experience;
